import {RepairIndex} from '@modules/repairs/types';
import {CategoryIndex} from '../categories/types';
import {UserIndex} from '../hospital_users/types';
import {CompanyIndex} from '../companies/types';
import {ProductIndex} from '../products/types';
import {HospitalRoom} from '../hospital_places/types';
import {DeviceLender} from '../rentals/api';
import {HospitalDepartment} from '../hospital_departments/api';
import {NoteFieldDataTypeValues} from './constants';

export type ProductStatusConst = 'ready' | 'working' | 'disabled' | 'uninspected' | 'repairing';
export type ProductStatusLabel = '待機中' | '貸出中' | '修理中' | '点検待ち' | '廃棄済み';
export type HospitalProductLogStatusConst = 'ready' | 'working' | 'disabled' | 'preparation';
export type HospitalProductLogTypeConst = 'status_change' | 'comment';

export type WaysOfPurchaseConst =
  | 'purchase'
  | 'lease'
  | 'rental'
  | 'alternative'
  | 'demo'
  | 'gift'
  | 'transfer_control';

export type WaysOfPurchaseLabel = '購入' | 'リース' | 'レンタル' | '代替品' | 'デモ機' | '寄贈' | '移管';

export type HospitalProductIndex = {
  hashId: string;
  wholeProductHashId: string;
  managementId: string;
  serialNumber: string;
  lotNumber: string | null;
  optionalBarcode: string | null;
  gs1Barcode: string | null;
  catalogPrice: number | null;
  name: string;
  status: ProductStatusConst;
  displayName: string;
  thumbnailUrl: string;
  maker: CompanyIndex;
  categories: CategoryIndex[];
  dateOfPurchase: string;
  isBaseUnit: boolean;
  isShared: boolean;
  permanentlyAssigned: boolean;
  purchasedNationalExpense: boolean;
  depreciationAmount: number;
  waysOfPurchase: WaysOfPurchaseConst;
  bookValue: number;
  legalDurableYear: number;
  isMaintenanceContract: boolean;
  assetRegisterNumber: string;
  dateOfDisposal: string;
  reasonOfDisposal: string;
  // notes20まで汎用項目
  notes: string;
  notes2: string;
  notes3: string;
  notes4: string;
  notes5: string;
  notes6: string;
  notes7: string;
  notes8: string;
  notes9: string;
  notes10: string;
  notes11: string;
  notes12: string;
  notes13: string;
  notes14: string;
  notes15: string;
  notes16: string;
  notes17: string;
  notes18: string;
  notes19: string;
  notes20: string;

  createdAt: string;
  updatedAt: string;
  hospitalRoom?: HospitalRoom;
  hospitalRoomHashId?: string;
  rentHospitalRoomHashId?: string;
  rentHospitalRoom?: HospitalRoom;
  createdBy: UserIndex;
  // レンタル項目
  rentalId?: string;
  rentalDealerName?: string;
  rentalFee?: number;
  rentalStartDate?: string;
  rentalDueDate?: string;
  rentalReturnDate?: string;
  // リース項目
  leaseId?: string;
  leaseDealerName?: string;
  leaseFee?: number;
  leaseStartDate?: string;
  leaseDueDate?: string;
  leaseReturnDate?: string;

  periodicInspectionStartDate?: string;
  wholeProduct?: ProductIndex;
  approvalNumber?: string;
  janCode?: string;
  className?: string;
  hospitalHashId?: string;
  hospitalDealer?: HospitalDealer;
  hospitalDealerHashId?: string;
  hospitalDepartment?: HospitalDepartment;
  hospitalDepartmentHashId?: string;

  channelHashId?: string;
  channelNumber?: number;
  isTransmitter: boolean;
  deliveryPrice?: number;
  taxIncluded?: boolean;
  taxRate?: number;

  numberPeriodInspectionResultsInPeriod: number | null; // n日以内 (貸出設定より設定できる) に予定されている該当製品の定期点検の数

  jmdnCode: number | undefined; // JMDNコード
  isSpecificMaintain: boolean; // 特定保守製品 true:該当 false:非該当
  newJanCode?: string; // GTIN(GS1のコード)
  purposeOfDemonstration: string | undefined; // デモの目的
  demonstrationStartDate: string | undefined; // デモ開始日時
  demonstrationEndDate: string | undefined; // デモ終了日時
};

/**
 * getHospitalProductLogsのレスポンス
 */
export type HospitalProductLogIndex = {
  description: string;
  hashId: string;
  hospitalHashId: string;
  hospitalProductHashId: string;
  logType: HospitalProductLogTypeConst;
  statusFrom: HospitalProductLogStatusConst;
  statusTo: HospitalProductLogStatusConst;
  pinned: boolean;
  createdAt: string;
  createdBy: UserIndex;
  updatedAt: string;
  updatedBy: UserIndex;
  isArchived: boolean;
  archivedByHashId: UserIndex | null;
  archivedBy: UserIndex | null;
  rentalRoom: HospitalRoom | null;
  deviceLender: DeviceLender | null;
  registererBy: UserIndex | null;
  movedAt: string | null;
};

export type HospitalProductDetail = {
  createdBy: UserIndex;
  updatedBy: UserIndex;
  // 親機の情報 API未マージでエラーにならないようにオプショナル
  parentUnitDisplayName?:string;
  parentUnitHospitalProductHashId?:string;
  parentUnitManagementId?:string;
  parentUnitName?:string;
} & HospitalProductIndex;

export type HospitalProductRentalHistoriesIndex = {
  className: string;
  isSpecificMaintained?: boolean;
  janCode?: string;
  jmdnCode?: number;
  makerDurableYear?: number;
  makerInspectionStartDate?: Date;
  newJanCode?: string;
} & HospitalProductIndex;

/** 担当代理店情報 */
export type HospitalDealer = {
  hashId: string;
  hospitalHashId: string;
  name: string;
  createdAt: string;
  createdBy?: string;
  updatedAt: string;
  updatedBy?: string;
  deletedBy?: string;
};

export type HospitalProductNoteSettingsOptions = {
  values: HospitalProductNoteSettingsOptionsValue[];
};
export type HospitalProductNoteSettingsOptionsValue = {
  value: number;
  name: string;
};

export type HospitalProductNoteSettings = {
  hospitalHashId: string;
  noteSettings: NoteSettings;
};

export type NoteFieldDataTypes = typeof NoteFieldDataTypeValues[number];

/**
 * 以下のような数値を含むプロパティにインデックスシグネチャでアクセス出来る為の型定義
 *
 * ```tsx
 * const testParam = {
 * notesName: '備考_1',
 * notesType: 'text',
 * notes2Name: '備考/2',
 * notes2Type: 'text',
 * notes3Name: '333',
 * notes3Type: 'text',
 * notes4Name: '備考4',
 * notes4Type: 'text',
 * notes5Name: '備考5',
 * notes5Type: 'text',
 * notes6Name: '備考6',
 * notes6Type: 'text',
 * notes7Name: '備考7',
 * notes7Type: 'text',
 * notes8Name: '備考8',
 * notes8Type: 'text',
 * notes9Name: '備考9',
 * notes9Type: 'text',
 * notes10Name: '備考10',
 * notes10Type: 'text',
 * notes11Name: '備考11',
 * notes11Type: 'text',
 * notes12Name: '備考12',
 * notes12Type: 'text',
 * notes13Name: '備考13',
 * notes13Type: 'text',
 * notes14Name: '備考14',
 * notes14Type: 'text',
 * notes15Name: '備考15',
 * notes15Type: 'text',
 * notes16Name: '備考16',
 * notes16Type: 'text',
 * notes17Name: '備考17',
 * notes17Type: 'text',
 * notes18Name: '備考18',
 * notes18Type: 'text',
 * notes19Name: '備考19',
 * notes19Type: 'text',
 * notes20Name: '備考20',
 * notes20Type: 'text',
 * notesVisible: true,
 * notes2Visible: true,
 * notes3Visible: true,
 * notes4Visible: true,
 * notes5Visible: true,
 * notes6Visible: false,
 * notes7Visible: false,
 * notes8Visible: true,
 * notes9Visible: true,
 * notes10Visible: true,
 * notes11Visible: true,
 * notes12Visible: true,
 * notes13Visible: true,
 * notes14Visible: true,
 * notes15Visible: true,
 * notes16Visible: false,
 * notes17Visible: false,
 * notes18Visible: true,
 * notes19Visible: true,
 * notes20Visible: true,
 * notesTypeOption: null,
 * notes2TypeOption: null,
 * notes3TypeOption: null,
 * notes4TypeOption: null,
 * notes5TypeOption: null,
 * notes6TypeOption: null,
 * notes7TypeOption: null,
 * notes8TypeOption: null,
 * notes9TypeOption: null,
 * notes10TypeOption: null,
 * notes11TypeOption: null,
 * notes12TypeOption: null,
 * notes13TypeOption: null,
 * notes14TypeOption: null,
 * notes15TypeOption: null,
 * notes16TypeOption: null,
 * notes17TypeOption: null,
 * notes18TypeOption: null,
 * notes19TypeOption: null,
 * notes20TypeOption: null,
 * };
 * ```
 */
export type NoteSettings = {
  [key in
    | `notes${string | ''}`
    | `notes${string | ''}Name`
    | `notes${string | ''}Visible`
    | `notes${string | ''}Type`
    | `notes${string | ''}TypeOption`]: key extends `notes${string | ''}Name`
    ? string
    : key extends `notes${string | ''}Visible`
    ? boolean
    : key extends `notes${string | ''}Type`
    ? NoteFieldDataTypes
    : key extends `notes${string | ''}TypeOption`
    ? HospitalProductNoteSettingsOptions | null
    : string | boolean | NoteFieldDataTypes | HospitalProductNoteSettingsOptions | null;
};

export enum HospitalProductActionType {
  HOSPITAL_PRODUCT_SET_LIST = 'HOSPITAL_PRODUCT_SET_LIST',
}

export type HospitalProductSetListAction = {
  type: HospitalProductActionType.HOSPITAL_PRODUCT_SET_LIST;
  payload: {
    hospitalProducts: HospitalProductIndex[];
  };
};

export type HospitalProductAction =
  | HospitalProductSetListAction;

export type HospitalProductState = {
  hospitalProducts: HospitalProductIndex[];
};

export enum RepairHistoryActionType {
  REPAIR_HISTORY_SET_LIST = 'REPAIR_HISTORY_SET_LIST',
}

export type RepairHistorySetListAction = {
  type: RepairHistoryActionType.REPAIR_HISTORY_SET_LIST;
  payload: {
    repairs: RepairIndex[];
  };
};

export type RepairHistoryAction =
  | RepairHistorySetListAction;

export type RepairHistoryState = {
  repairs: RepairIndex[];
};
