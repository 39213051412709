import {Box, SxProps, Typography} from '@mui/material';
import React from 'react';
import {FormProvider} from 'react-hook-form';
import {ProductIndex} from '@modules/products/types';
import {SubmitDrawer} from './form/SubmitDrawer';
import {WholeProductForm} from './form/WholeProductForm';
import {useWholeProductForm} from './form/hooks';
import {ProductHeader} from './ui/ProductHeader';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

const containerStyle: SxProps = {
  display: 'flex',
  height: 'calc(100vh - 56px - 36px - 48px)',
  width: '100%',
  backgroundColor: 'white',
};

const defaultColumn: SxProps = {
  width: '50%',
  padding: '0px',
};

const columnLeftStyle: SxProps = {
  ...defaultColumn,
  '@media (min-width: 1025px)': {
    flexGrow: 1,
  },
};

const columnRightStyle: SxProps = {
  ...defaultColumn,
  borderLeft: '1px solid #D1D5DB',
  minWidth: '490px',
  '@media (min-width: 1025px)': {
    width: '600px',
  },
};

type Props = {
  wholeProduct: ProductIndex;
  refetch: () => void;
};

export const WholeProductContent = ({wholeProduct, refetch}: Props) => {
  const methods = useWholeProductForm(wholeProduct);
  const {isReadOnly} = useMyRole();

  return (
    <FormProvider {...methods}>
      <ProductHeader wholeProduct={wholeProduct} />
      <Box sx={containerStyle}>
        <Box sx={columnLeftStyle}>
          <WholeProductForm />
        </Box>
        <Box sx={columnRightStyle}>
          <Typography>機種共通ファイル・添付文書</Typography>
        </Box>
      </Box>

      {!isReadOnly && <SubmitDrawer wholeProduct={wholeProduct} refetch={refetch} />}
    </FormProvider>
  );
};
