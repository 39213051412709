import React from 'react';
import {BuildOutlined, DescriptionOutlined} from '@mui/icons-material';
import {InspectionIcon} from '../DialogContent/MenuContent/MenuIcons/Inspection';
import {MenuItemSetting} from '../types';
import {FEATURE_HIT_4866_HOSPITAL_PRODUCT_MENU_FLAG} from '@constants/constants';

export const HospitalProductReadMessages = {
  failed: '機器情報の取得に失敗しました',
} as const;

export const MenuItemValues = ['hospitalProductDetail', 'inspectionStart', 'faultRepairDetail'] as const;

export const MenuItemSettings: readonly MenuItemSetting[] = [
  {
    icon: <DescriptionOutlined />,
    label: '機器詳細',
    value: 'hospitalProductDetail',
  },
  ...(FEATURE_HIT_4866_HOSPITAL_PRODUCT_MENU_FLAG
    ? ([
        {
          icon: <InspectionIcon viewBox="3 3 18 18" htmlColor="#0052CC" />,
          label: '点検開始',
          value: 'inspectionStart',
        },
      ] as MenuItemSetting[])
    : []),
  {
    icon: <BuildOutlined />,
    label: '修理詳細',
    value: 'faultRepairDetail',
  },
] as const;
