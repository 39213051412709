import React, {useState} from 'react';
import {styled, Box, Button, DialogActions, DialogTitle} from '@mui/material';
import {InspectionType, isInspectionType} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/types';
import {InspectionTypeSelector} from './InspectionTypeSelector';

type Props = {
  onSubmit: (inspectionType: InspectionType) => void;
  onBack: () => void;
};

const InputForm = styled(Box)({
  marginTop: '24px',
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const InspectionTypeSelect = ({onSubmit, onBack}: Props) => {
  const [selectedInspectionType, setSelectedInspectionType] = useState<InspectionType | null>(null);

  const handleSelect = (value: string) => {
    if (!isInspectionType(value)) return;

    setSelectedInspectionType(value);
  };

  const handleSubmit = async () => {
    if (!selectedInspectionType) return;

    onSubmit(selectedInspectionType);
  };

  const resetSelectedInspectionType = () => {
    setSelectedInspectionType(null);
  };

  const handleBack = () => {
    resetSelectedInspectionType();
    onBack();
  };

  return (
    <>
      <DialogTitle sx={{padding: 0, minWidth: '586px', fontWeight: 700}}>開始する点検を選択</DialogTitle>
      <InputForm>
        <InspectionTypeSelector selectedInspectionType={selectedInspectionType} onSelect={handleSelect} />
      </InputForm>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleBack}>
          機器メニュー画面に戻る
        </Button>
        <Button
          variant="contained"
          sx={{backgroundColor: '#0052CC', '&.Mui-disabled': {color: '#FFFFFF'}}}
          disabled={!selectedInspectionType}
          onClick={handleSubmit}>
          次へ
        </Button>
      </Actions>
    </>
  );
};
