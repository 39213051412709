import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {ProductIndex} from '@modules/products/types';
import {z} from 'zod';

export const classNameSchema = z.enum(['one', 'two', 'three', 'four']);
export const specificMaintainSchema = z.enum(['apply', 'not_apply']);
export const CategoryIndexSchema = z.object({
  hashId: z.string().min(1),
  name: z.string().min(1),
  depth: z.number().min(1),
});

export const MakerIndexSchema = z.object({
  hashId: z.string().optional(), // 新規登録を許容するため
  name: z.string().min(1),
});
export type MakerIndex = z.infer<typeof MakerIndexSchema>;

const wholeProductFormValueSchema = z.object({
  rootCategory: CategoryIndexSchema,
  narrowCategory: CategoryIndexSchema,
  maker: MakerIndexSchema,
  displayName: z.string().min(1),
  name: z.string().min(1),
  approvalNumber: z.optional(z.string()),
  jmdnCode: z.optional(z.number()),
  janCode: z.optional(z.string()),
  newJanCode: z.optional(z.string()),
  className: classNameSchema.optional(),
  catalogPrice: z.optional(z.number()),
  specificMaintain: specificMaintainSchema.optional(),
});

export type WholeProductFormValue = z.infer<typeof wholeProductFormValueSchema>;

export const useWholeProductForm = (wholeProduct: ProductIndex) => {
  const initialWholeProductFormValue: WholeProductFormValue = {
    rootCategory: wholeProduct.categories[0],
    narrowCategory: wholeProduct.categories[1],
    maker: {
      hashId: wholeProduct.maker.hashId,
      name: wholeProduct.maker.name,
    },
    name: wholeProduct.name,
    displayName: wholeProduct.displayName,
    approvalNumber: wholeProduct.approvalNumber ?? '',
    jmdnCode: wholeProduct.jmdnCode,
    janCode: wholeProduct.janCode,
    newJanCode: wholeProduct.newJanCode,
    className: wholeProduct.className,
    catalogPrice: wholeProduct.catalogPrice,
    specificMaintain:
      wholeProduct.isSpecificMaintain === undefined
        ? undefined
        : wholeProduct.isSpecificMaintain
          ? 'apply'
          : 'not_apply',
  };

  const methods = useForm<WholeProductFormValue>({
    mode: 'onChange',
    resolver: zodResolver(wholeProductFormValueSchema),
    defaultValues: initialWholeProductFormValue,
  });

  return methods;
};
