import {StrUtil} from '@front-libs/helpers';
import {Button, Dialog, DialogProps, SxProps, Theme, Typography} from '@mui/material';
import {CSSProperties} from '@mui/material/styles/createMixins';
import React, {useMemo} from 'react';
import {DialogActions} from '../../atom/DialogActions';
import {DialogContent} from '../../atom/DialogContent';
import {DialogTitle} from '../../atom/DialogTitle';
import {DialogProps as DialogHandlerProps} from './DialogHandlerProps';

const defaultProps = {
  positiveButtonLabel: '保存',
  negativeButtonLabel: 'キャンセル',
  dialogTitleProps: {},
  positiveButtonProps: {},
  maxWidth: 'sm' as DialogProps['maxWidth'],
};

export type SimpleDialogProps = {
  paperSx?: SxProps<Theme>;
  title?: React.ReactNode;
  content?: React.ReactNode;
  positiveButtonLabel?: string;
  negativeButtonLabel?: string;
  maxWidth?: DialogProps['maxWidth'];
  disabledButton?: boolean;
  boldTitle?: boolean;
  children: React.ReactNode | string;
} & DialogHandlerProps;

export const SimpleDialog = (props: SimpleDialogProps) => {
  const {
    open,
    paperSx,
    title,
    children,
    positiveButtonLabel,
    negativeButtonLabel,
    actions,
    maxWidth,
    disabledButton = false,
  } = {...defaultProps, ...props};

  const shapedContent = useMemo(() => (typeof children === 'string' ? StrUtil.nl2br(children) : children), [children]);

  return (
    <Dialog
      open={!!open}
      PaperProps={{
        sx: {
          ...paperStyle,
          ...paperSx,
        },
      }}
      maxWidth={maxWidth}>
      {title && (
        <DialogTitle onClose={actions.reject}>
          <Typography component="span" sx={boldTitleStyle}>
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent sx={contentStyle}>{shapedContent}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={buttonStyle}
          onClick={actions.resolve}
          disabled={disabledButton}>
          {positiveButtonLabel}
        </Button>
        <Button variant="text" color="primary" sx={buttonStyle} onClick={actions.reject}>
          {negativeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const paperStyle: CSSProperties = {
  padding: '32px',
  minWidth: '600px',
  maxWidth: '90%',
  overflow: 'visible',
};

const boldTitleStyle: SxProps = {
  fontWeight: 'bold',
  fontSize: '20px',
};

const contentStyle: SxProps = {
  fontSize: '14px',
  padding: '32px 0 48px',
};
const buttonStyle: SxProps = {
  fontSize: '15px',
};
