import React, {useMemo} from 'react';
import {TextField, Theme, styled} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import {useAtom, PrimitiveAtom} from 'jotai';
import {Selector, Option} from '@molecules/Selector';
import {DateField, DateFieldValue} from '@molecules/DateField';
import {Product} from '../../state';
import {
  formatAssetRegisterNumber,
  formatBarcode,
  formatGS1Barcode,
  formatLotNumber,
  formatManagementId,
  formatSerialNumber,
} from '@modules/hospital_products/utls';
import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {Dayjs} from 'dayjs';
import {NoteSettings} from '@modules/hospital_products/types';
import {newProductStatusOptions} from '../constants';

type Props = {
  productAtom: PrimitiveAtom<Product>;
  index: number;
  isSelected: (key: string) => boolean;
  handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, key: string) => void;
  noteSettings: NoteSettings;
  hospitalDealerOptions: Option<string>[];
  hospitalDepartmentOptions: Option<string>[];
};

const StyledCell = styled(TableCell)({
  padding: 8,
  minWidth: 196,
});

const StyledFormField = styled(TextField)(({theme}: {theme: Theme}) => ({
  '& .MuiInputBase-root': {
    height: 36,
    background: theme.palette.common.white,
  },
}));

const StyledMultiTextField = styled(TextField)(({theme}: {theme: Theme}) => ({
  '& .MuiInputBase-root': {
    padding: 8,
    background: theme.palette.common.white,
  },
}));
const StyledDateField = styled(DateField)(({theme}: {theme: Theme}) => ({
  '& .MuiInputBase-root': {
    height: 36,
    background: theme.palette.common.white,
  },
}));

type NotesFieldProps = {
  type: string;
  value: string | null;
  product: keyof Product;
  visible: boolean;
  options: Option<string>[];
  callBack: (value: string | null, productKey: keyof Product) => void;
};

/**
 * 汎用項目用のField
 * @param param0
 * @returns
 */
const NotesField = ({type, product, value, visible, options, callBack}: NotesFieldProps) => {
  if (visible === false) return null;

  if (type === 'date') {
    return (
      <StyledCell>
        <StyledDateField
          value={value}
          onChange={(data: DateFieldValue) => {
            callBack(data === null ? data : data + '', product as keyof Product);
          }}
        />
      </StyledCell>
    );
  }
  if (type === 'select') {
    return (
      <StyledCell>
        <Selector
          value={value}
          options={options}
          onChange={(e: Option<string>) => {
            callBack(e.value, product as keyof Product);
          }}
          menuPosition={'fixed'}
        />
      </StyledCell>
    );
  }

  return (
    <StyledCell>
      <StyledMultiTextField
        defaultValue={value}
        variant="outlined"
        multiline={true}
        onBlur={(e) => {
          callBack(e.target.value, product as keyof Product);
        }}
      />
    </StyledCell>
  );
};

/**
 * 製品情報を表形式で表示・編集するためのUIコンポーネント
 * 各製品情報（管理番号、シリアル番号、ロット番号など）に対して入力フィールドを提供し、
 * 製品データの更新を可能にします。
 * @see `Apps/ProductsList/pc/ProductsRegistrationDialog/Step2/TableForm/TableForm.tsx`の定数「row」と数が一致している必要あり
 * @param {Props} props - このコンポーネントに渡されるプロパティ
 * @param {Atom<Product>} props.productAtom - 製品情報を管理するJotaiのAtom
 * @param {number} props.index - 製品のインデックス（表の行番号）
 * @param {Function} props.handleClick - チェックボックスのクリックイベントハンドラ
 * @param {Function} props.isSelected - 製品が選択されているか判断する関数
 * @returns {React.ReactElement}
 */
export const TableFormContents = ({
  productAtom,
  index,
  handleClick,
  isSelected,
  noteSettings,
  hospitalDealerOptions,
  hospitalDepartmentOptions,
}: Props) => {
  const labelId = `enhanced-table-checkbox-${index}`;
  const {myInfo} = useMyInfo();

  const [product, setProduct] = useAtom(productAtom);
  const isItemSelected = isSelected(product?.uuid + '');
  const {data: hospitalRooms} = useFetchHospitalRooms(myInfo.hospitalHashId);

  const hospitalRoomOptions = useMemo(
    () =>
      hospitalRooms.map((room) => ({
        label: HospitalRoomFormatter.getFullRoom(room),
        value: room.hashId,
      })),
    [hospitalRooms]
  );

  const getNotesFieldOptionsFromNoteSettings = (
    noteSettings: NoteSettings,
    numStr: string
  ): {label: string; value: string}[] => {
    const opt = noteSettings[`notes${numStr}TypeOption`];
    if (opt === null) return [];
    if (typeof opt === 'string') return [];
    if (typeof opt === 'boolean') return [];

    return opt.values.map((v) => ({label: v.name, value: v.value.toString()}));
  };

  /**
   * 汎用項目の入力時ハンドラ
   * @param value
   * @param productKey
   */
  const notesFieldInputHandler = (value: string | null, productKey: keyof Product) => {
    setProduct((oldValue) => {
      return {
        ...oldValue,
        [productKey]: value,
      };
    });
  };

  // TODO:項目追加や並び順の変更がやりにくいので個別関数にする等リファクタリングしたい
  return (
    <TableRow hover tabIndex={-1} key={product.uuid}>
      <TableCell padding="checkbox">
        <Checkbox
          onClick={(event) => handleClick(event, product?.uuid + '')}
          aria-checked={isItemSelected}
          checked={isItemSelected}
          inputProps={{'aria-labelledby': labelId}}
        />
      </TableCell>
      {/* 1.管理番号 */}
      <StyledCell>
        <StyledFormField
          key={product.managementId}
          variant="outlined"
          defaultValue={product.managementId}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                managementId: formatManagementId(e.target.value),
              };
            });
          }}
        />
      </StyledCell>
      {/* 2.シリアル番号 */}
      <StyledCell>
        <StyledFormField
          variant="outlined"
          key={product.serialNumber}
          defaultValue={product.serialNumber}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                serialNumber: formatSerialNumber(e.target.value),
              };
            });
          }}
        />
      </StyledCell>
      {/* 3.ロット番号 */}
      <StyledCell>
        <StyledFormField
          variant="outlined"
          key={product.lotNumber}
          defaultValue={product.lotNumber}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                lotNumber: formatLotNumber(e.target.value),
              };
            });
          }}
        />
      </StyledCell>
      {/* 4.貸出区分 */}
      <StyledCell>
        <Selector
          value={product.permanentlyAssigned}
          options={[
            {label: '貸出不可', value: true},
            {label: '貸出可', value: false},
          ]}
          onChange={(e: Option<boolean>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                permanentlyAssigned: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </StyledCell>
      {/* 5.管理部署 */}
      <StyledCell>
        <Selector
          value={product.hospitalDepartmentHashId}
          options={hospitalDepartmentOptions}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                hospitalDepartmentHashId: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </StyledCell>

      {/* 6.稼働状況 */}
      <StyledCell>
        <Selector
          value={product.status}
          options={newProductStatusOptions}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                status: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </StyledCell>
      {/* 7.機器管理場所 */}
      <StyledCell>
        <Selector
          value={product.hospitalRoomHashId}
          options={hospitalRoomOptions}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                hospitalRoomHashId: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </StyledCell>
      {/* 8.購入日 */}
      <StyledCell>
        <StyledDateField
          value={product.dateOfPurchase}
          onChange={(data: string | number | Date | Dayjs | null | undefined) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                dateOfPurchase: data === null ? data : data + '',
              };
            });
          }}
        />
      </StyledCell>
      {/* 9.親機・子機 */}
      <StyledCell>
        <Selector
          value={product.isBaseUnit}
          options={[
            {label: '親機', value: true},
            {label: '子機', value: false},
          ]}
          onChange={(e: Option<boolean>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                isBaseUnit: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </StyledCell>
      {/* 10.購入区分 */}
      <StyledCell>
        <Selector
          value={product.waysOfPurchase}
          options={[
            {label: '購入', value: 'purchase'},
            {label: 'リース', value: 'lease'},
            {label: 'レンタル', value: 'rental'},
            {label: '代替品', value: 'alternative'},
            {label: 'デモ機', value: 'demo'},
            {label: '寄贈', value: 'gift'},
            {label: '移管', value: 'transfer_control'},
          ]}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                waysOfPurchase: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </StyledCell>
      {/* 11.院内耐用年数（年） */}
      <StyledCell>
        <StyledFormField
          key={product.legalDurableYear}
          variant="outlined"
          type="number"
          defaultValue={product.legalDurableYear}
          inputProps={{min: 1}}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                legalDurableYear: parseInt(Number(e.target.value) >= 0 ? e.target.value : '0', 10),
              };
            });
          }}
        />
      </StyledCell>
      {/* 12.保守契約 */}
      <StyledCell>
        <Selector
          value={product.isMaintenanceContract}
          options={[
            {label: '保守契約', value: true},
            {label: '保守契約外', value: false},
          ]}
          onChange={(e: Option<boolean>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                isMaintenanceContract: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </StyledCell>
      {/* 13.担当代理店 */}
      <StyledCell>
        <Selector
          value={product.hospitalDealerHashId}
          options={hospitalDealerOptions}
          onChange={(e: Option<string>) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                hospitalDealerHashId: e.value,
              };
            });
          }}
          menuPosition={'fixed'}
        />
      </StyledCell>
      {/* 14.資産番号 */}
      <StyledCell>
        <StyledFormField
          variant="outlined"
          key={product.assetRegisterNumber}
          defaultValue={product.assetRegisterNumber}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                assetRegisterNumber: formatAssetRegisterNumber(e.target.value),
              };
            });
          }}
        />
      </StyledCell>
      {/* 15.廃棄日 */}
      <StyledCell>
        <StyledDateField
          value={product.dateOfDisposal}
          onChange={(data: DateFieldValue) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                dateOfDisposal: data,
              };
            });
          }}
        />
      </StyledCell>
      {/* 16.廃棄理由 */}
      <StyledCell>
        <StyledMultiTextField
          multiline={true}
          variant="outlined"
          key={product.reasonOfDisposal}
          defaultValue={product.reasonOfDisposal}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                reasonOfDisposal: e.target.value,
              };
            });
          }}
        />
      </StyledCell>
      {/* 17~36.汎用1~20 */}
      {Array.from({length: 20}, (_, i) => i + 1).map((v) => {
        const numString = v === 1 ? '' : String(v);
        /** DateField用にproductのValueの参照を返す */
        const getValue = (_numString: string) => {
          switch (_numString) {
            case '':
              return product.notes;
            case '2':
              return product.notes2;
            case '3':
              return product.notes3;
            case '4':
              return product.notes4;
            case '5':
              return product.notes5;
            case '6':
              return product.notes6;
            case '7':
              return product.notes7;
            case '8':
              return product.notes8;
            case '9':
              return product.notes9;
            case '10':
              return product.notes10;
            case '11':
              return product.notes11;
            case '12':
              return product.notes12;
            case '13':
              return product.notes13;
            case '14':
              return product.notes14;
            case '15':
              return product.notes15;
            case '16':
              return product.notes16;
            case '17':
              return product.notes17;
            case '18':
              return product.notes18;
            case '19':
              return product.notes19;
            case '20':
              return product.notes20;
            default:
              return null;
          }
        };

        return (
          <NotesField
            key={`noteFields_${v}`}
            value={getValue(numString)}
            type={noteSettings[`notes${numString}Type`]}
            product={`notes${numString}` as keyof Product}
            visible={noteSettings[`notes${numString}Visible`]}
            options={getNotesFieldOptionsFromNoteSettings(noteSettings, numString)}
            callBack={notesFieldInputHandler}
          />
        );
      })}
      {/* 37.バーコード読み取り値 */}
      <StyledCell>
        <StyledFormField
          variant="outlined"
          key={product.optionalBarcode}
          defaultValue={product.optionalBarcode}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                optionalBarcode: formatBarcode(e.target.value),
              };
            });
          }}
        />
      </StyledCell>
      {/* 38.GS1-128 */}
      <StyledCell>
        <StyledFormField
          variant="outlined"
          key={product.rawBarcode}
          defaultValue={product.rawBarcode}
          onBlur={(e) => {
            setProduct((oldValue) => {
              return {
                ...oldValue,
                rawBarcode: formatGS1Barcode(e.target.value),
              };
            });
          }}
        />
      </StyledCell>
    </TableRow>
  );
};
