import React, {useRef, useState} from 'react';
import {InnerPageHeader} from '@components/molecules/Header/InnerPageHeader';
import {useNavigate} from 'react-router-dom';
import {Button, SxProps} from '@mui/material';
import {ProductsPartsBody, ProductsPartsBodyRef} from './ProductsPartsBody';
import {PartsDialog} from './PartsDialog';
import {PartsDialogFormValues} from './schema';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {postWholeProductParts} from '@modules/whole_product_parts/api';
import {getAxiosErrorData} from '@front-libs/core';
import {openSnackBar} from '@front-libs/ui';

const RegisterPartsButtonStyle: SxProps = {
  minHeight: '32px',
  padding: '8px 16px',
  fontSize: '14px',
};

const RegisterPartsButton = ({onClick}: {onClick: VoidFunction}) => {
  return (
    <Button color="primary" variant="contained" sx={RegisterPartsButtonStyle} onClick={onClick}>
      部品・交換品を新規登録
    </Button>
  );
};

/**
 * 部品・交換品一覧画面
 */
export const ProductsParts = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {myInfo} = useMyInfo();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const childRef = useRef<ProductsPartsBodyRef>(null);
  const handleClickPrevButton = () => {
    navigate(`/products/`);
  };

  const handleRegisterParts = () => {
    setOpen(true);
  };

  const onSubmit = async (values: PartsDialogFormValues) => {
    try {
      await postWholeProductParts(myInfo.hospitalHashId, values);
      setErrorMessage(undefined);
      setOpen(false);
      childRef.current?.refetchData();
      openSnackBar('部品・交換品を登録しました。');
    } catch (error) {
      const errorMessage = getAxiosErrorData(error);
      if (errorMessage?.status === 409) {
        setErrorMessage('こちらの部品・交換品は登録済みです。');
      } else {
        setErrorMessage('予期しないエラーが発生しました。');
      }
    }
  };

  return (
    <>
      <InnerPageHeader
        prevLabel="機器一覧"
        headerLabel="部品・交換品"
        onClickPrevButton={handleClickPrevButton}
        rightNode={<RegisterPartsButton onClick={handleRegisterParts} />}
      />
      <ProductsPartsBody ref={childRef} />
      {open && (
        <PartsDialog
          open={open}
          title="部品・交換品の新規登録"
          positiveButtonLabel="登録"
          errorMessage={errorMessage}
          onSubmit={onSubmit}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};
