import React, {useState} from 'react';
import {TextField, MenuItem, Autocomplete, SxProps, Theme, AutocompleteRenderInputParams, Paper} from '@mui/material';
import {FormLayoutField} from './const';
import {Controller, useFormContext} from 'react-hook-form';
import {MakerIndex, WholeProductFormValue} from './hooks';
import {CategoryIndex} from '@modules/categories/types';
import {FetchCategoriesParams, useDescendantCategoryQuery, useFetchRootCategoriesQuery} from '@modules/categories/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {FetchCompaniesParam, useFetchCompaniesQuery} from '@modules/companies/api';
import {CompanyIndex} from '@modules/companies/types';

type AutoCompleteTextFieldProps = {
  params: AutocompleteRenderInputParams;
  label: string;
  isError?: boolean;
  helperText?: string;
};

export const AutoCompleteDefaultProps = {
  clearIcon: false,
  slotProps: {
    paper: {
      sx: {
        fontSize: '14px',
      } as SxProps<Theme>,
    },
  },
};

const AutoCompleteTextField = ({params, label, isError, helperText}: AutoCompleteTextFieldProps) => {
  return (
    <TextField
      {...params}
      label={label}
      error={isError}
      helperText={helperText}
      variant="standard"
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        sx: {marginTop: '18px', fontSize: '14px'},
      }}
    />
  );
};

export const defaultFormStyle: SxProps<Theme> = (theme) => ({
  '& label': {
    fontSize: '14px',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(0, 1.5px) scale(0.9)',
  },
  '& .MuiInputBase-root': {
    borderBottom: 'none',
    fontSize: '14px',
    marginTop: '16px',
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
    '& .MuiInputBase-input': {
      fontSize: '14px', // 入力テキスト部分のフォントサイズのみ変更
    },
  },
});

const menuItemStyle: SxProps = {
  fontSize: '14px',
};

/**
 * 機種詳細のFieldを生成するファクトリ関数。
 * @param layoutField - フォームフィールドの定義オブジェクト。
 * @returns JSX要素 - フォームフィールドに対応するコンポーネント。
 */
export const FieldFactory = (layoutField: FormLayoutField) => {
  const {register, getValues} = useFormContext<WholeProductFormValue>();
  const currentValue = getValues(layoutField.name); // 初期値を取得
  switch (layoutField.type) {
    case 'select':
      return (
        <TextField
          sx={defaultFormStyle}
          fullWidth
          variant="standard"
          {...register(layoutField.name)}
          select
          label={layoutField.label}
          InputProps={{disableUnderline: true}}
          defaultValue={currentValue || ''}>
          {layoutField.options?.map((option) => (
            <MenuItem sx={menuItemStyle} key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );
    case 'text':
    case 'number':
      return (
        <TextField
          sx={defaultFormStyle}
          fullWidth
          variant="standard"
          InputProps={{disableUnderline: true}}
          {...register(layoutField.name)}
          label={layoutField.label}
          type={layoutField.type}
        />
      );
    case 'auto_complete':
      switch (layoutField.name) {
        case 'rootCategory':
          return <RootCategoryField layoutField={layoutField} />;
        case 'narrowCategory':
          return <NarrowCategoryField layoutField={layoutField} />;
        case 'maker':
          return <MakerField layoutField={layoutField} />;
        default:
          return null;
      }
    default:
      return null;
  }
};

type RootCategoryFieldProps = {
  layoutField: FormLayoutField;
};

/**
 * 機種詳細の大分類の項目
 * @returns JSX要素 - フォームフィールドに対応するコンポーネント。
 */
const RootCategoryField = ({layoutField}: RootCategoryFieldProps) => {
  const {control, setValue} = useFormContext();

  const param: FetchCategoriesParams = {
    depth: 0,
  };
  const {isLoading, data} = useFetchRootCategoriesQuery(param);

  const getOptionLabel = (option: CategoryIndex) => {
    // nameフィールドを表示
    return option.name;
  };

  const isOptionEqualToValue = (option: CategoryIndex, value: CategoryIndex) => {
    return option.hashId === value?.hashId;
  };

  return (
    <Controller
      name="rootCategory"
      control={control}
      render={({field}) => (
        <Autocomplete<CategoryIndex>
          {...field}
          options={data ?? []}
          getOptionLabel={getOptionLabel}
          value={field.value}
          onChange={(_, value) => {
            field.onChange(value); // 親カテゴリー選択時にフィールドを更新
            setValue('narrowCategory', null); // 親カテゴリー変更時にサブカテゴリーをリセット
          }}
          isOptionEqualToValue={isOptionEqualToValue}
          loading={isLoading}
          renderInput={(params) => <AutoCompleteTextField params={params} label={layoutField.label} />}
          {...AutoCompleteDefaultProps}
        />
      )}
    />
  );
};

type NarrowCategoryFieldProps = {
  layoutField: FormLayoutField;
};

/**
 * 機種詳細の小分類分類の項目
 * 大分類の項目に応じて、小分類の項目の一覧を取得する。
 * @param layoutField - フォームフィールドの定義オブジェクト。
 * @returns JSX要素 - フォームフィールドに対応するコンポーネント。
 */
const NarrowCategoryField = ({layoutField}: NarrowCategoryFieldProps) => {
  const {watch, control} = useFormContext();
  const rootCategoryHashID = watch('rootCategory')?.hashId;

  const {isLoading, data} = useDescendantCategoryQuery(rootCategoryHashID);

  const getOptionLabel = (option: CategoryIndex) => {
    // nameフィールドを表示
    return option.name;
  };

  const isOptionEqualToValue = (option: CategoryIndex, value: CategoryIndex) => {
    return option.hashId === value?.hashId;
  };

  return (
    <Controller
      name="narrowCategory"
      control={control}
      render={({field}) => (
        <Autocomplete<CategoryIndex>
          {...field}
          options={data ?? []}
          getOptionLabel={getOptionLabel} // nameフィールドを表示
          isOptionEqualToValue={isOptionEqualToValue}
          value={field.value}
          onChange={(_, value) => {
            field.onChange(value); // 親カテゴリー選択時にフィールドを更新
          }}
          loading={isLoading}
          renderInput={(params) => <AutoCompleteTextField params={params} label={layoutField.label} />}
          {...AutoCompleteDefaultProps}
        />
      )}
    />
  );
};

type MakerFieldProps = {
  layoutField: FormLayoutField;
};

const MakerField = ({layoutField}: MakerFieldProps) => {
  const {myInfo} = useMyInfo();
  const methods = useFormContext();
  const {control} = methods;
  const [searchQuery, setSearchQuery] = useState<string>(); // 検索クエリの状態

  const param: FetchCompaniesParam = {
    page: 0,
    perPage: 100,
    ...(searchQuery && {name: searchQuery}), // searchQueryが存在する場合だけnameを追加
  };

  // メーカーの一覧を取得する
  const {isLoading, data} = useFetchCompaniesQuery(myInfo.hospitalHashId, param);

  const getMakerIndex = (inputValue: string): MakerIndex => {
    return {
      hashId: undefined,
      name: inputValue, // 新規項目の名前
    };
  };

  // 新規項目は文字列で処理
  const getOptionLabel = (option: CompanyIndex | MakerIndex | string, isOptionRender?: boolean) => {
    if (typeof option === 'string') return option;

    // オプション表示のときのみ、新規項目を追加する文言を追加
    if (isOptionRender && option.hashId === undefined) {
      return `"${option.name}"を追加`;
    }

    return option.name;
  };

  const isOptionEqualToValue = (
    option: CompanyIndex | MakerIndex | string,
    value: CompanyIndex | MakerIndex | string
  ) => {
    // optionが文字列でvalueがオブジェクトの場合、valueのnameプロパティと比較
    if (typeof option === 'string' && typeof value !== 'string') {
      return option === value.name;
    }

    // valueが文字列でoptionがオブジェクトの場合、optionのnameプロパティと比較
    if (typeof value === 'string' && typeof option !== 'string') {
      return value === option.name;
    }

    // 両方が文字列の場合
    if (typeof option === 'string' && typeof value === 'string') {
      return option === value;
    }

    // 両方がオブジェクトの場合はhashIdで比較
    if (typeof option !== 'string' && typeof value !== 'string') {
      return option.hashId === value.hashId;
    }

    // どの条件にも合致しない場合はfalseを返す
    return false;
  };

  const filterOptions = (options: (CompanyIndex | MakerIndex | string)[], params: {inputValue: string}) => {
    const filteredOptions = options.filter((option) =>
      (typeof option === 'string' ? option : option.name).toLowerCase().includes(params.inputValue.toLowerCase())
    );

    if (
      params.inputValue &&
      !filteredOptions.some((option) => (typeof option === 'string' ? option : option.name) === params.inputValue)
    ) {
      filteredOptions.push(getMakerIndex(params.inputValue));
    }

    return filteredOptions;
  };

  return (
    <Controller
      name="maker"
      control={control}
      render={({field}) => (
        <Autocomplete<CompanyIndex | MakerIndex | string>
          {...field}
          options={data?.data ?? []}
          getOptionLabel={getOptionLabel}
          filterOptions={filterOptions}
          onInputChange={(_e, value, reason) => {
            // Makerが100件以下の場合は検索クエリ更新不要
            if (data && data?.data.length <= 100) return;

            // reasonがinputの場合は検索クエリを更新
            if (reason === 'input') {
              setSearchQuery(value);
            } else {
              // reasonがclearもしくはresetの場合は検索クエリをリセット
              setSearchQuery(undefined);
            }
          }}
          onChange={(_, value) => {
            if (typeof value === 'string') {
              field.onChange(getMakerIndex(value)); // 新規項目をフィールドに設定
            } else {
              // 既存項目を選択
              field.onChange(value);
            }
          }}
          renderOption={(props, option) => {
            const modifiedLabel = getOptionLabel(option, true);
            // props とオリジナルのスタイルはそのまま渡す
            return <li {...props}>{modifiedLabel}</li>;
          }}
          isOptionEqualToValue={isOptionEqualToValue}
          loading={isLoading}
          renderInput={(params) => <AutoCompleteTextField params={params} label={layoutField.label} />}
          {...AutoCompleteDefaultProps}
        />
      )}
    />
  );
};
