import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {UserIndex} from '@modules/hospital_users/types';

const isSentryEnabled = import.meta.env.VITE_APP_ENV === 'production' || import.meta.env.VITE_SENTRY_DEBUG === 'true';

export const initializeSentry = () => {
  if (isSentryEnabled) {
    Sentry.init({
      dsn: 'https://f1c12b17b83244d1a76d7a86d725043a@o517571.ingest.sentry.io/5625560',
      environment: import.meta.env.VITE_APP_ENV,
      release: import.meta.env.VITE_APP_VERSION,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
};

export const setSentryUser = (user: UserIndex) => {
  if (isSentryEnabled) {
    Sentry.setUser({
      id: user.hashId,
      username: user.username,
      email: user.email,
      hospitalHashId: user.hospitalHashId,
    });
  }
};

export const logger = {
  error: (e: unknown) => {
    Sentry.captureException(e);
  },
  info: (message: string) => {
    Sentry.captureMessage(message);
  },
};
