import React, {useState} from 'react';
import {styled, Box, Button, DialogActions, DialogTitle} from '@mui/material';
import {UserIndex} from '@modules/hospital_users/types';
import {InspectionList} from '@modules/inspections/types';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {RequiredLabel} from '@molecules/FormRequiredLabel';
import {InspectionSummary, buildInspectionSummaryRows} from '../InspectionSummary';
import {InspectionSelector} from './InspectionSelector';
import {UserSelector} from './UserSelector';

type Props = {
  inspectionResult: InspectionResultIndex;
  hospitalUsers: UserIndex[];
  allInspections: InspectionList[];
  wholeProductInspections: InspectionList[];
  defaultInspectorHashId: string;
  onBack: () => void;
  onSearchInspection: (name: string) => void;
  onSubmit: (inspectionHashId: string, inspectorHashId: string) => void;
};

const Description = styled(Box)({
  marginTop: '32px',
});

const InputForm = styled(Box)({
  marginTop: '24px',
  '& > div:nth-of-type(n+2)': {
    marginTop: '24px',
  },
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const InspectionStart = ({
  inspectionResult,
  hospitalUsers,
  allInspections,
  wholeProductInspections,
  defaultInspectorHashId,
  onBack,
  onSearchInspection,
  onSubmit,
}: Props) => {
  const [selectedInspectionHashId, setSelectedInspectionHashId] = useState<string>(inspectionResult.inspectionHashId);
  const [selectedInspectorHashId, setSelectedInspectorHashId] = useState<string>(defaultInspectorHashId);
  const canSubmit = !!selectedInspectionHashId && !!selectedInspectorHashId;
  const title = inspectionResult.status === 'uncompleted' ? '実施途中の点検を開始' : '点検を開始';

  const resetInputValues = () => {
    setSelectedInspectionHashId(inspectionResult.inspectionHashId);
    setSelectedInspectorHashId(defaultInspectorHashId);
  };

  const handleSearchInspection = (name: string) => {
    onSearchInspection(name);
  };
  const handleSelectInspection = (hashId?: string) => {
    if (!hashId) return;

    setSelectedInspectionHashId(hashId);
  };
  const handleSelectInspector = (hashId?: string) => {
    if (!hashId) return;

    setSelectedInspectorHashId(hashId);
  };

  const handleBack = () => {
    resetInputValues();
    onBack();
  };

  const handleSubmit = () => {
    if (!canSubmit) return;

    onSubmit(selectedInspectionHashId, selectedInspectorHashId);
  };

  const renderInspectionSelector = () => {
    if (inspectionResult.status !== 'unplanned') return null;

    return (
      <Box>
        <RequiredLabel>点検表</RequiredLabel>
        <InspectionSelector
          allInspections={allInspections}
          wholeProductInspections={wholeProductInspections}
          selectedHashId={selectedInspectionHashId}
          onSearch={handleSearchInspection}
          onSelect={handleSelectInspection}
        />
      </Box>
    );
  };
  const renderUserSelector = () => {
    return (
      <Box>
        <RequiredLabel>点検者</RequiredLabel>
        <UserSelector
          placeholder="点検者"
          users={hospitalUsers}
          selectedHashId={selectedInspectorHashId}
          onSelect={handleSelectInspector}
        />
      </Box>
    );
  };

  return (
    <>
      <DialogTitle sx={{padding: 0, minWidth: '586px', fontWeight: 700}}>{title}</DialogTitle>
      <Description>
        <InspectionSummary data={buildInspectionSummaryRows(inspectionResult)} />
      </Description>
      <InputForm>
        {renderInspectionSelector()}
        {renderUserSelector()}
      </InputForm>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} onClick={handleBack}>
          前に戻る
        </Button>
        <Button
          variant="contained"
          sx={{backgroundColor: '#0052CC', '&.Mui-disabled': {color: '#FFFFFF'}}}
          disabled={!canSubmit}
          onClick={handleSubmit}>
          点検開始
        </Button>
      </Actions>
    </>
  );
};
