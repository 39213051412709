import {Box, SxProps, Typography} from '@mui/material';
import {ProductThumbnail} from './ProductThumbnail';
import React from 'react';
import {ProductIndex} from '@modules/products/types';

type ProductHeaderProps = {
  wholeProduct: ProductIndex;
};

const boxStyle: SxProps = {
  width: '100%',
  height: '44px',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  borderRadius: '20px 20px 0 0',
  borderBottom: '1px solid #D1D5DB',
};

const displayTextStyle: SxProps = {
  fontWeight: 'bold',
  marginLeft: '124px',
};

export const ProductHeader = ({wholeProduct}: ProductHeaderProps) => {
  return (
    <Box sx={boxStyle}>
      <Typography component="p" sx={displayTextStyle}>
        {wholeProduct.displayName}
      </Typography>
      <ProductThumbnail wholeProductHashId={wholeProduct.hashId} src={wholeProduct.thumbnailUrl ?? ''} />
    </Box>
  );
};
