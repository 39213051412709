import {datadogRum} from '@datadog/browser-rum';
import {UserIndex} from '@modules/hospital_users/types';

const isDatadogRumEnabled =
  import.meta.env.VITE_APP_ENV === 'production' || import.meta.env.VITE_DATADOG_DEBUG === 'true';

export const initializeDatadogRum = () => {
  if (isDatadogRumEnabled) {
    datadogRum.init({
      applicationId: '591c55af-0ac4-4006-9423-86809a1c0e53',
      clientToken: 'pub84cd199c3314b4d02e11135f00a6c6f9',
      env: import.meta.env.VITE_APP_ENV,
      site: 'datadoghq.com',
      service: 'hitotsu_front_v1',
      sampleRate: 100,
      premiumSampleRate: 0,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingOrigins: [/https:\/\/.*\.hitotsu\.life/, /http:\/\/localhost/],
    });

    datadogRum.startSessionReplayRecording();
  }
};

export const setDatadogRumUser = (user: UserIndex) => {
  if (isDatadogRumEnabled) {
    datadogRum.setUser({
      id: user.hashId,
      hospitalHashId: user.hospitalHashId,
    });
  }
};
