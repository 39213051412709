import {Close, Search} from '@mui/icons-material';
import {Box, Checkbox, IconButton, List, ListItem, ListItemText, SxProps, TextField} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {DialogPagination} from './DialogPagination';
import {useFetchHospitalWholeProductsQuery} from '@modules/products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {DialogContentProps} from './DialogContents';
import {Controller} from 'react-hook-form';
import {FormLabelBadgeNarrow} from '@components/molecules/FormLabelBadge/FormLabelBadgeNarrow';

const SearchTextField = ({onChange}: {onChange: (text: string) => void}) => {
  return (
    <TextField
      label="機種名・型式で検索"
      variant="outlined"
      size="small"
      InputProps={{
        endAdornment: <Search />,
      }}
      InputLabelProps={{
        sx: {
          fontSize: '14px',
        },
      }}
      defaultValue={''}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
};

type ProductListItemProps = {
  data: SelectorDataType;
  onClick: (index: SelectorDataType) => void;
  selectedIds?: string[];
};

const ProductListItem = ({data, onClick, selectedIds: selectedValue}: ProductListItemProps) => {
  const checked = selectedValue?.some((item) => item === data.id);

  return (
    <ListItem
      onClick={() => {
        onClick(data);
      }}>
      <Checkbox edge="start" checked={!!checked} tabIndex={-1} disableRipple />
      <ListItemText primary={data.name} sx={{'& > .MuiTypography-root': {fontSize: '14px'}}} />
    </ListItem>
  );
};

const SelectedListItem = <T,>({
  data,
  label,
  index,
  onclick,
}: {data: T; label: string; index: number; onclick: (label: T) => void}) => {
  return (
    <ListItem
      tabIndex={index}
      secondaryAction={
        <IconButton edge="end" onClick={() => onclick(data)}>
          <Close sx={{color: '#8B95A6', fontSize: '24px'}} />
        </IconButton>
      }
      sx={{border: '1px solid #8B95A6', borderRadius: '5px'}}>
      <ListItemText
        sx={{padding: '8px', '& > .MuiTypography-root': {fontSize: '14px'}}}
        primary={label}
        onClick={() => {
          onclick(data);
        }}
      />
    </ListItem>
  );
};

const nameSort = (a: SelectorDataType, b: SelectorDataType) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

const selectedListStyle: SxProps = {
  maxHeight: '428px',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  paddingRight: '16px',
  boxSizing: 'border-box',
  '& .MuiListItem-root': {padding: '0 16px'},
};
const productListStyles: SxProps = {
  height: '360px',
  overflow: 'auto',
  boxSizing: 'border-box',
  '& .MuiListItem-root': {padding: '0 16px'},
};

const columnStyle: SxProps = {display: 'flex', gap: '16px', flexDirection: 'column', width: '100%'};
const labelStyle: SxProps = {display: 'flex', gap: '8px'};
const DiverStyle: SxProps = {width: '1px', backgroundColor: '#D1D5DB', margin: '0 24px'};
const notSelectedStyle: SxProps = {color: '#8B95A6', fontSize: '14px', marginTop: '8px'};

export type SelectorDataType = {
  id: string;
  name: string;
};

export const WholeProductSelector = ({control, setValue, selectedData}: DialogContentProps) => {
  const {myInfo} = useMyInfo();
  const [page, setPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState<SelectorDataType[]>(selectedData || []); // 選択されたアイテム
  const [searchText, setSearchText] = useState(''); // 検索用のテキスト

  const param = {
    page: page - 1,
    perPage: 100,
    order: 'displayName',
    ...(searchText && {name: searchText}),
  };
  const {data, totalCount} = useFetchHospitalWholeProductsQuery(myInfo.hospitalHashId, param);
  const wholeProducts: SelectorDataType[] = useMemo(
    () => data.map((v) => ({id: v.hashId, name: `${v.displayName} ${v.name}`})),
    [data]
  );

  // チェックボックスのトグル
  const handleToggle = (item: SelectorDataType) => {
    setSelectedItems((prevSelectedItems) => {
      const isSelected = prevSelectedItems.some((selectedItem) => selectedItem.id === item.id);
      if (isSelected) {
        // チェックを外す
        return prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id);
      } else {
        // 新しいアイテムを追加してnameでソート
        return [...prevSelectedItems, item].sort(nameSort);
      }
    });
  };

  // 右ペインのリストネームをクリックしてチェックを外す
  const handleRemove = (itemId: string) => {
    setSelectedItems((prevSelectedItems) => prevSelectedItems.filter((item) => item.id !== itemId));
  };

  useEffect(() => {
    setValue(
      'assignWholeProductHashIds',
      selectedItems.map((item) => item.id)
    );
  }, [selectedItems]);

  return (
    <>
      {/* 左ペイン */}
      <Box sx={columnStyle}>
        <Box sx={labelStyle}>
          対象機種
          <FormLabelBadgeNarrow required={false} />
        </Box>
        <SearchTextField
          onChange={(text) => {
            setSearchText(text);
          }}
        />
        <Controller
          name="assignWholeProductHashIds"
          control={control}
          render={({field: {value}}) => (
            <List sx={productListStyles}>
              {wholeProducts.map((product) => (
                <ProductListItem key={product.id} data={product} onClick={handleToggle} selectedIds={value} />
              ))}
            </List>
          )}
        />
        <DialogPagination
          totalCount={totalCount}
          currentPage={page}
          pageSize={100}
          maxDisplayPosition={100}
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
        />
      </Box>
      {/* 縦Diver */}
      <Box sx={DiverStyle} />
      {/* 右ペイン */}
      <Box sx={columnStyle}>
        <Box mt="40px">選択した機種</Box>
        {selectedItems.length === 0 && <Box sx={notSelectedStyle}>選択されていません</Box>}
        {selectedItems.length > 0 && (
          <List sx={selectedListStyle}>
            {selectedItems.map((item, index) => (
              <SelectedListItem<SelectorDataType>
                key={`selectedItem${index}`}
                data={item}
                index={index}
                label={item.name}
                onclick={(data) => {
                  handleRemove(data.id);
                }}
              />
            ))}
          </List>
        )}
      </Box>
    </>
  );
};
