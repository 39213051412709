import React, {useState} from 'react';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {useInspectionStartMenuStore} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/hooks';
import {InspectionType, OperationType} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/types';
import {InspectionTypeSelect} from './InspectionStartMenuContent/InspectionTypeSelect';
import {InspectionResultSelect} from './InspectionStartMenuContent/InspectionResultSelect';
import {
  InspectionStart,
  InspectionSkip,
  InspectionUpdateScheduledTime,
  InspectionAdhoc,
} from './InspectionStartMenuContent/InspectionOperate';

type Props = {
  hospitalProduct: HospitalProductIndex;
  onBack: () => void;
};

export const InspectionStartMenu = ({hospitalProduct, onBack}: Props) => {
  const [selectedInspectionType, setSelectedInspectionType] = useState<InspectionType | null>(null);
  const [selectedInspectionResult, setSelectedInspectionResult] = useState<InspectionResultIndex | null>(null);
  const [selectedOperationType, setSelectedOperationType] = useState<OperationType | null>(null);
  const {
    myInfo,
    inspectionResultState,
    listInspectionResults,
    createAdhocInspectionResult,
    skipInspectionResult,
    updateInspectionResultScheduledTime,
    hospitalUserState,
    listHospitalUsers,
    inspectionState,
    listInspections,
  } = useInspectionStartMenuStore();

  /* 状態のリセット処理 */
  const resetSelectedOperationType = () => {
    setSelectedOperationType(null);
  };
  const resetSelectedInspectionResult = () => {
    resetSelectedOperationType(); // NOTE: 点検をリセットするので、選択中の操作もリセットする
    setSelectedInspectionResult(null);
  };
  const resetSelectedInspectionType = () => {
    resetSelectedInspectionResult(); // NOTE: 点検タイプをリセットするので、選択中の点検もリセットする
    setSelectedInspectionType(null);
  };

  /** 点検に関する操作をするためのデータ準備処理 */
  const prepareDataForOperation = async (inspectionResult: InspectionResultIndex, operationType: OperationType) => {
    if (!selectedInspectionType) return;
    if (operationType === 'update_scheduled_time') return;

    const promises: Promise<void>[] = [];

    // ユーザー一覧の取得
    promises.push(listHospitalUsers());

    if (operationType !== 'inspect') {
      await Promise.all(promises);
      return;
    }
    if (inspectionResult.status !== 'unplanned') {
      await Promise.all(promises);
      return;
    }

    // 点検表一覧の取得
    promises.push(listInspections(selectedInspectionType, hospitalProduct.wholeProductHashId));
    await Promise.all(promises);
  };

  /* handler */
  /** 点検タイプの選択handler */
  const handleSelectInspectionType = async (inspectionType: InspectionType) => {
    switch (inspectionType) {
      case 'pre_use':
      case 'in_use':
      case 'post_use':
      case 'periodic':
        await listInspectionResults(hospitalProduct.hashId, inspectionType);
        break;
      case 'adhoc':
        await Promise.all([listHospitalUsers(), listInspections(inspectionType, hospitalProduct.wholeProductHashId)]);
        setSelectedOperationType('adhoc_inspect');
        break;
    }
    setSelectedInspectionType(inspectionType);
  };
  /** 点検予定の選択handler */
  const handleSelectInspectionResult = async (
    inspectionResult: InspectionResultIndex,
    operationType: OperationType
  ) => {
    if (!selectedInspectionType) return;

    await prepareDataForOperation(inspectionResult, operationType);
    setSelectedInspectionResult(inspectionResult);
    setSelectedOperationType(operationType);
  };
  /** 点検開始handler */
  const handleStartInspection = (inspectionHashId: string, inspectorHashId: string) => {
    if (selectedInspectionResult === null) return;

    // NOTE: 点検画面の点検開始時と同じく、statusにuncomplatedを指定している。
    window.open(
      `/inspections/${inspectionHashId}/result/${selectedInspectionResult.hashId}?status=uncompleted&inspectorHashId=${inspectorHashId}`,
      '_blank'
    );
  };
  /** 点検スキップhandler */
  const handleSkipInspection = async (skippedTime: string, selectedSkippedByHashId: string, skipReason: string) => {
    if (selectedInspectionResult === null) return;

    const {inspectionHashId, hashId} = selectedInspectionResult;
    await skipInspectionResult(inspectionHashId, hashId, skippedTime, selectedSkippedByHashId, skipReason);
  };
  const handleUpdateScheduledTime = async (scheduledTime: string) => {
    if (selectedInspectionResult === null) return;

    const {inspectionHashId, hashId} = selectedInspectionResult;
    await updateInspectionResultScheduledTime(inspectionHashId, hashId, scheduledTime);
  };
  const handleStartAdhocInspection = async (
    inspectionHashId: string,
    inspectorHashId: string,
    scheduledTime: string
  ) => {
    const res = await createAdhocInspectionResult(
      hospitalProduct.hashId,
      inspectionHashId,
      inspectorHashId,
      scheduledTime
    );

    // NOTE: 点検画面の臨時点検開始時と同じく、statusにuncomplatedを指定している。
    window.open(
      `/inspections/${inspectionHashId}/result/${res.hashId}?status=uncompleted&inspectorHashId=${inspectorHashId}`,
      '_blank'
    );
  };
  /** 点検開始メニューから戻るhandler */
  const handleBack = () => {
    resetSelectedInspectionType();
    onBack();
  };
  /** 点検タイプの再選択handler */
  const handleReselectType = () => {
    resetSelectedInspectionType();
  };
  /** 点検予定の再選択handler */
  const handleReselectInspectionResult = async () => {
    if (selectedInspectionType === null) return;

    await listInspectionResults(hospitalProduct.hashId, selectedInspectionType);
    resetSelectedInspectionResult();
  };
  /** 点検表の検索handler */
  const handleSearchInspection = (name: string) => {
    if (!selectedInspectionType) return;

    // NOTE: 点検画面の点検開始時と同じく、Searchboxで検索文字列を入れる度にAPIへ問い合わせるようにしている。これにより、選択肢に表示する点検表はページネーションしていないが、点検表が101件以上の場合でもSeachboxで検索することで選択肢に表示される。
    listInspections(selectedInspectionType, hospitalProduct.wholeProductHashId, name);
  };

  /* 表示制御 */
  /** 点検タイプ選択 表示処理 */
  const renderInspectionTypeSelect = () => {
    return <InspectionTypeSelect onSubmit={handleSelectInspectionType} onBack={handleBack} />;
  };
  /** 点検予定選択 表示処理 */
  const renderInspectionResultSelect = () => {
    if (selectedInspectionType === null) return null;

    return (
      <InspectionResultSelect
        inspectionType={selectedInspectionType}
        inspectionResults={inspectionResultState.results}
        onSubmit={handleSelectInspectionResult}
        onBack={handleReselectType}
      />
    );
  };
  /** 点検に関する操作 表示処理 */
  const renderInspectionOperate = () => {
    switch (selectedOperationType) {
      case 'inspect':
        if (selectedInspectionResult === null) return null;

        return (
          <InspectionStart
            inspectionResult={selectedInspectionResult}
            hospitalUsers={hospitalUserState.users}
            allInspections={inspectionState.allInspections}
            wholeProductInspections={inspectionState.wholeProductInspections}
            defaultInspectorHashId={myInfo.hashId}
            onBack={handleReselectInspectionResult}
            onSearchInspection={handleSearchInspection}
            onSubmit={handleStartInspection}
          />
        );
      case 'skip':
        if (selectedInspectionResult === null) return null;

        return (
          <InspectionSkip
            inspectionResult={selectedInspectionResult}
            hospitalUsers={hospitalUserState.users}
            defaultSkippedByHashId={myInfo.hashId}
            onBack={handleReselectInspectionResult}
            onSubmit={handleSkipInspection}
          />
        );
      case 'update_scheduled_time':
        if (selectedInspectionResult === null) return null;

        return (
          <InspectionUpdateScheduledTime
            inspectionResult={selectedInspectionResult}
            onBack={handleReselectInspectionResult}
            onSubmit={handleUpdateScheduledTime}
          />
        );
      case 'adhoc_inspect':
        return (
          <InspectionAdhoc
            hospitalUsers={hospitalUserState.users}
            allInspections={inspectionState.allInspections}
            wholeProductInspections={inspectionState.wholeProductInspections}
            defaultInspectorHashId={myInfo.hashId}
            onSearchInspection={handleSearchInspection}
            onSubmit={handleStartAdhocInspection}
          />
        );
      default:
        return null;
    }
  };
  /** 点検開始メニュー全体 表示処理 */
  const render = () => {
    if (selectedInspectionType === null) return renderInspectionTypeSelect();
    if (selectedOperationType === null) return renderInspectionResultSelect();

    return renderInspectionOperate();
  };

  return render();
};
