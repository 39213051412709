import {classNameOpts} from '@Apps/ProductRegistration/constants';
import {WholeProductFormValue} from './hooks';

export type FormLayoutField =
  | {type: 'text' | 'number'; label: string; name: keyof WholeProductFormValue}
  | {type: 'select'; label: string; name: keyof WholeProductFormValue; options: Array<{label: string; value: string}>}
  | {type: 'auto_complete'; label: string; name: keyof WholeProductFormValue};

export const wholeProductDetailFormFields: FormLayoutField[] = [
  {type: 'auto_complete', label: '大分類', name: 'rootCategory'},
  {type: 'auto_complete', label: '小分類', name: 'narrowCategory'},
  {type: 'auto_complete', label: 'メーカー名', name: 'maker'},
  {type: 'text', label: '機種名称', name: 'displayName'},
  {type: 'text', label: '型式', name: 'name'},
  {type: 'text', label: '承認番号', name: 'approvalNumber'},
  {type: 'number', label: 'JMDNコード', name: 'jmdnCode'},
  {type: 'text', label: 'JANコード', name: 'janCode'},
  {type: 'text', label: 'GTIN', name: 'newJanCode'},
  {
    type: 'select',
    label: '特定保守製品',
    name: 'specificMaintain',
    options: [
      {label: '該当', value: 'apply'},
      {label: '非該当', value: 'not_apply'},
    ],
  },
  {
    type: 'select',
    label: 'クラス分類',
    name: 'className',
    options: classNameOpts,
  },
  {type: 'number', label: '定価', name: 'catalogPrice'},
];
