export type HospitalProductRequiredProperty = {
  hospitalProductRequiredPropertyHashID: string;
  property: string;
};

export type ListHospitalProductRequiredPropertiesResponse = {
  data: HospitalProductRequiredProperty[];
};

export type UpdateHospitalProductRequiredPropertiesRequest = {
  properties: string[];
};

export type UpdateHospitalProductRequiredPropertiesResponse = {
  data: HospitalProductRequiredProperty[];
};

export type HospitalProductRequiredPropertyItem = {
  name: string;
  value: string;
};

export type ListHospitalProductRequiredPropertyItemsResponse = {
  data: HospitalProductRequiredPropertyItem[];
};

export enum HospitalProductRequiredPropertyActionType {
  HOSPITAL_PRODUCT_REQUIRED_PROPERTY_SET_LIST = 'HOSPITAL_PRODUCT_REQUIRED_PROPERTY_SET_LIST',
  HOSPITAL_PRODUCT_REQUIRED_PROPERTY_SET_LIST_ITEMS = 'HOSPITAL_PRODUCT_REQUIRED_PROPERTY_SET_LIST_ITEMS',
}

export type HospitalProductRequiredPropertySetListAction = {
  type: HospitalProductRequiredPropertyActionType.HOSPITAL_PRODUCT_REQUIRED_PROPERTY_SET_LIST;
  payload: {
    hospitalProductRequiredProperties: HospitalProductRequiredProperty[];
  };
};

export type HospitalProductRequiredPropertySetListItemsAction = {
  type: HospitalProductRequiredPropertyActionType.HOSPITAL_PRODUCT_REQUIRED_PROPERTY_SET_LIST_ITEMS;
  payload: {
    hospitalProductRequiredPropertyItems: HospitalProductRequiredPropertyItem[];
  };
};

export type HospitalProductRequiredPropertyAction =
  | HospitalProductRequiredPropertySetListAction
  | HospitalProductRequiredPropertySetListItemsAction;

export type HospitalProductRequiredPropertyState = {
  hospitalProductRequiredProperties: HospitalProductRequiredProperty[];
  hospitalProductRequiredPropertyItems: HospitalProductRequiredPropertyItem[];
};

/** 機器登録の必須項目として設定可能な値。「設定中の必須項目」と「各画面で表示している項目」の紐付けなどに利用する。 */
export enum HospitalProductRequiredPropertyItemValue {
  /** シリアル番号 */
  SerialNumber = 'serial_number',
  /** ロット番号 */
  LotNumber = 'lot_number',
  /** 貸出区分 */
  PermanentlyAssigned = 'permanently_assigned',
  /** 管理部署 */
  // NOTE:ACC-316の要件にて管理部署と所属部署のデータを統合。データ統合に伴い、keyをhospitalDepartmentHashIdに変更している。
  HospitalDepartment = 'hospital_department',
  /** 稼働状況 */
  Status = 'status',
  /** 機器管理場所 */
  HospitalRoom = 'hospital_room',
  /** 購入日 */
  DateOfPurchase = 'date_of_purchase',
  /** 親機・子機 */
  IsBaseUnit = 'is_base_unit',
  /** 購入区分 */
  WaysOfPurchase = 'ways_of_purchase',
  /** 院内耐用年数（年） */
  LegalDurableYear = 'legal_durable_year',
  /** 保守契約 */
  IsMaintenanceContract = 'is_maintenance_contract',
  /** 担当代理店 */
  HospitalDealer = 'hospital_dealer',
  /** 資産番号 */
  AssetRegisterNumber = 'asset_register_number',
  /** 廃棄日 */
  DateOfDisposal = 'date_of_disposal',
  /** 廃棄理由 */
  ReasonOfDisposal = 'reason_of_disposal',
  /** バーコード読み取り値 */
  OptionalBarcode = 'optional_barcode',
  /** GS1-128 */
  Gs1Barcode = 'gs1_barcode',
}
