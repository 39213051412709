export type UserIndex = {
  hashId: string;
  hospitalHashId: string;
  email: string;
  username: string;
  thumbnailUrl?: string;
  thumbnailFile?: {
    hashId: string;
    hospitalHashId: string;
    category: string;
    driver: string;
    fileType: string;
    originalName: string;
    fileName: string;
    path: string;
    url: string;
  };
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  isShared: boolean;
  lastSignInAt?: Date;
  nextInitializeStep?: number;
  initialized?: boolean;
  occupations?: string[];
  isSafetyControlManager?: boolean;
  isUserAdmin: boolean;
  createdAt: string;
  createdBy: UserIndex | null;
  updatedAt: string;
  updatedBy: UserIndex | null;
  deletedBy: UserIndex | null;
  deletedAt: string | null;
  permissions?: string[];
  canAccessMgmtDashboard?: boolean;
  sessionExpirationTimeSec: number | null;
  rentalLayoutPreference: 'default_order' | 'customize';
};

export type UserResource = {
  hashId: string;
  name: string;
  applicationHashId: string;
  ownerOrganizationHashId: string;
};

export type Role = {
  hashId: string;
  name: string;
  ownerOrganizationHashId: string;
  applicationHashId: string;
  isShared: boolean;
};

export type RoleUsers = {
  role: Role;
  users: {user: UserIndex}[];
};

export enum HospitalUserActionType {
  HOSPITAL_USER_SET_LIST = 'HOSPITAL_USER_SET_LIST',
}

export type HospitalUserSetListAction = {
  type: HospitalUserActionType.HOSPITAL_USER_SET_LIST;
  payload: {
    users: UserIndex[];
  };
};

export type HospitalUserAction = HospitalUserSetListAction;

export type HospitalUserState = {
  users: UserIndex[];
};
