import React, {useState} from 'react';
import {styled, Box, Button, DialogActions, DialogTitle, Alert} from '@mui/material';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {InspectionTypeLabelMap} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/constants';
import {InspectionType, OperationType} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/types';
import {ErrorMessage} from '@components/organisms/Header/pc/GeneralMenu/HospitalProductMenu/DialogContent/ErrorMessage';
import {InspectionSummary} from '../InspectionSummary';
import {InspectionResultSelector} from './InspectionResultSelector';

type Props = {
  inspectionType: InspectionType;
  inspectionResults: InspectionResultIndex[];
  onSubmit: (inspectionResult: InspectionResultIndex, operationType: OperationType) => void;
  onBack: () => void;
};

const Description = styled(Box)({
  marginTop: '32px',
});
const AlertInfo = styled(Alert)({
  padding: '2px 16px',
  backgroundColor: '#E5EEFA',
  '.MuiAlert-icon,.MuiAlert-message': {color: '#0052CC'},
});

const InputForm = styled(Box)({
  marginTop: '24px',
  width: '720px',
  whiteSpace: 'nowrap',
  overflowX: 'scroll',
});

const Actions = styled(DialogActions)({
  marginTop: '40px',
  '& > button:nth-of-type(n+2)': {
    marginLeft: '16px',
  },
});

export const InspectionResultSelect = ({inspectionType, inspectionResults, onSubmit, onBack}: Props) => {
  const [selectedInspectionResult, setSelectedInspectionResult] = useState<InspectionResultIndex | null>(null);

  const handleSelect = (result: InspectionResultIndex) => {
    setSelectedInspectionResult(result);
  };

  const submit = (operationType: OperationType) => {
    if (!selectedInspectionResult) return;

    onSubmit(selectedInspectionResult, operationType);
  };

  const handleInspect = () => {
    submit('inspect');
  };

  const handleSkip = () => {
    submit('skip');
  };

  const handleUpdateScheduledTime = () => {
    submit('update_scheduled_time');
  };

  const renderAlert = () => {
    switch (inspectionType) {
      case 'periodic':
        return (
          <AlertInfo severity="info" sx={{marginTop: '24px'}}>
            こちらのリストには、予定月超過分の点検予定に加え、「当月＋2ヶ月」分の予定が表示されます。
          </AlertInfo>
        );
      default:
        return null;
    }
  };

  const renderUpdateScheduledTimeButton = () => {
    if (inspectionType !== 'periodic') return null;

    return (
      <Button
        variant="text"
        sx={{color: '#0052CC'}}
        disabled={!selectedInspectionResult}
        onClick={handleUpdateScheduledTime}>
        点検予定日を変更
      </Button>
    );
  };

  const resetSelectedInspectionResult = () => {
    setSelectedInspectionResult(null);
  };

  const handleBack = () => {
    resetSelectedInspectionResult();
    onBack();
  };

  if (inspectionResults.length === 0) {
    return (
      <ErrorMessage
        title="こちらの点検タイプの点検予定はありません"
        content={null}
        buttonText="点検タイプ選択画面に戻る"
        onBack={handleBack}
      />
    );
  }

  return (
    <>
      <DialogTitle sx={{padding: 0, minWidth: '720px', fontWeight: 700}}>開始する点検を選択</DialogTitle>
      <Description>
        <InspectionSummary data={[{label: '点検タイプ', value: InspectionTypeLabelMap[inspectionType]}]} />
        {renderAlert()}
      </Description>
      <InputForm>
        <InspectionResultSelector
          inspectionType={inspectionType}
          inspectionResults={inspectionResults}
          selectedInspectionResultHashId={selectedInspectionResult?.hashId ?? null}
          onSelect={handleSelect}
        />
      </InputForm>
      <Actions>
        <Button variant="text" sx={{color: '#0052CC'}} disabled={!selectedInspectionResult} onClick={handleSkip}>
          点検をスキップ
        </Button>
        {renderUpdateScheduledTimeButton()}
        <Button
          variant="contained"
          sx={{backgroundColor: '#0052CC', '&.Mui-disabled': {color: '#FFFFFF'}}}
          disabled={!selectedInspectionResult}
          onClick={handleInspect}>
          次へ
        </Button>
      </Actions>
    </>
  );
};
